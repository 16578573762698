/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
import React from 'react'
import pin from 'img/pin.svg'
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'
import styled from 'styled-components'

const MapWrapper = styled.section`
  position: relative;
  height: 500px;
  margin-bottom: -50px;
`

export class MapContainer extends React.Component<any> {
  public mapLoaded(map: any) {
    map.setOptions({
      styles: GoogleMapsSettings,
    })
  }

  render() {
    const latLong = this.props.fields.coordinates
      ? this.props.fields.coordinates.split(',')
      : [0, 1]

    return (
      <MapWrapper>
        <Map
          // @ts-ignore
          zoomControl={false}
          maxZoom={12}
          scrollWheel={false}
          draggable={false}
          streetViewControl={false}
          mapTypeControl={false}
          fullscreenControl={false}
          google={this.props.google}
          initialCenter={{
            lat: latLong[0],
            lng: latLong[1],
          }}
          containerStyle={{
            width: '100%',
            maxWidth: '100%',
          }}
          onReady={(mapProps, map) => this.mapLoaded(map)}
        >
          <Marker
            // @ts-ignore
            title="Test"
            name="Tasje"
            position={{
              lat: latLong[0],
              lng: latLong[1],
            }}
            icon={{
              url: pin,
              // @ts-ignore
              scaledSize: new google.maps.Size(75, 75),
            }}
          />
        </Map>
      </MapWrapper>
    )
  }
}
export default GoogleApiWrapper(() => ({
  apiKey: 'AIzaSyAmr5r8d6LKmu418qfE8hwGSk30of_yRRA',
  language: 'nl-NL',
  // @ts-ignore
}))(MapContainer)

const GoogleMapsSettings = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dadada',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#c9c9c9',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
]
